import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL, SLICE_NAME } from '@variable';
import { AddressBook } from '@interface/addressBook.interface';
import { CryptoCurrency } from '@enum/cryptocurrency.enum';
import { FeeWalletAttributes } from '@interface/initialState.interface';

const URL = `${API_URL}/admin`;

export const fetchWallets = createAsyncThunk(
  `${SLICE_NAME}/fetchWallets`,
  async (): Promise<AddressBook[]> => {
    const result = await axios(`${URL}/wallet-addresses`);
    return result.data;
  },
);
export const fetchFeeWalletAddress = createAsyncThunk(
  `${SLICE_NAME}/fetchFeeWalletAddress`,
  async (): Promise<FeeWalletAttributes> => {
    const result = await axios(`${URL}/fee-wallet-address`);
    return result.data;
  },
);

export const addAddress = createAsyncThunk(
  `${SLICE_NAME}/addAddress`,
  async (data: {
    address: string;
    privateKey: string;
    currency: string;
  }): Promise<AddressBook[]> => {
    const result = await axios.post(`${URL}/add-address`, data);
    return result.data;
  },
);

export const drainWallets = createAsyncThunk(
  `${SLICE_NAME}/drainWallets`,
  async (): Promise<{ message: string }> => {
    const result = await axios.post(`${URL}/drain-wallets`, {});
    return result.data;
  },
);

export const drainStorage = createAsyncThunk(
  `${SLICE_NAME}/drainStorage`,
  async (data: {
    amount?: number;
    currency: CryptoCurrency;
  }): Promise<{ message: string }> => {
    const result = await axios.post(`${URL}/drain-storage`, data);
    return result.data;
  },
);

export const sendTrx = createAsyncThunk(
  `${SLICE_NAME}/sendTrx`,
  async (data: {
    amount: number;
    addressId: string;
    toAddress: string;
  }): Promise<{ message: string }> => {
    const result = await axios.post(`${URL}/send-trx`, data);
    return result.data;
  },
);

export const hydrateWallet = createAsyncThunk(
  `${SLICE_NAME}/hydrateWallet`,
  async (walletId: string): Promise<AddressBook> => {
    const result = await axios.post(`${URL}/hydrate-wallet/${walletId}`);
    return result.data;
  },
);

export const stakeTrx = createAsyncThunk(
  `${SLICE_NAME}/stakeTrx`,
  async (data: {
    amount: number;
    stakeReason: string;
  }): Promise<AddressBook> => {
    const result = await axios.post(`${URL}/stake-fee-wallet-trx`, data);
    return result.data;
  },
);

export const unStakeTrx = createAsyncThunk(
  `${SLICE_NAME}/unStakeTrx`,
  async (data: {
    amount: number;
    stakeReason: string;
  }): Promise<AddressBook> => {
    const result = await axios.post(`${URL}/unstake-fee-wallet-trx`, data);
    return result.data;
  },
);

export const delegateResources = createAsyncThunk(
  `${SLICE_NAME}/delegateResources`,
  async (data: {
    amount: number;
    recipientAddress: string;
    resource: string;
  }): Promise<AddressBook> => {
    const result = await axios.post(`${URL}/delegate-resource`, data);
    return result.data;
  },
);

export const lockAddress = createAsyncThunk(
  `${SLICE_NAME}/lockAddress`,
  async (data: {
    addressId: string;
    isLocked: boolean;
  }): Promise<AddressBook> => {
    const result = await axios.post(`${URL}/lock-address`, data);
    return result.data;
  },
);
