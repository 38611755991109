import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { AdvancedImage } from '@cloudinary/react';
import { auto } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';

import {
  BusinessOwnerKycVerification,
  CompanyDirectorVerification,
  CompanyVerification,
  VerificationRequest,
} from '@interface/verificationRequest.interface';
import cld from '@localCloudinary';
import CompanyVerificationComponent from '@page/Requests/VerificationDialog/CompanyVerification';
import { useAppDispatch } from '@hook/hooks.hook';
import { approveOrRejectRequest } from '@action/request.action';
import VerificationDocType, {
  VerificationType,
} from '@enum/verificationDocType.enum';
import ConfirmationDialog from '@component/ConfirmationDialog';

import CompanyDirectorVerificationComponent from './CompanyDirectorVerification';
import BusinessOwnerKycVerificationComponent from './BusinessOwnerKycVerification';

interface VerificationDialogProps {
  open: boolean;
  onClose: () => void;
  verification: VerificationRequest;
}

const VerificationDialog: FC<VerificationDialogProps> = ({
  open,
  onClose,
  verification,
}) => {
  const dispatch = useAppDispatch();
  const [openConfirmRequestVerification, setOpenConfirmRequestVerification] =
    useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [reason, setReason] = useState('');
  const isCompanyVerification = 'cacDocUpload' in verification;
  const isCompanyDirectorVerification = 'fullName' in verification;
  const isBusinessOwnerKycVerification = 'firstName' in verification;

  const docType = (() => {
    if (isCompanyVerification) return VerificationDocType.COMPANY;
    if (isCompanyDirectorVerification)
      return VerificationDocType.COMPANY_DIRECTOR;
    return VerificationDocType.BUSINESS_OWNER_KYC;
  })();

  const confirmationDialogProps = {
    open: openConfirmRequestVerification,
    title: `Confirm ${isApproving ? 'Approval' : 'Rejection'}!`,
    subtitle: `Are you sure you want to ${
      isApproving ? 'approve' : 'reject'
    } this request?`,
    onClose: () => {
      setReason('');
      setOpenConfirmRequestVerification(false);
    },
    onConfirm: async () => {
      await dispatch(
        approveOrRejectRequest({
          docType,
          id: verification.id,
          action: isApproving
            ? VerificationType.APPROVE
            : VerificationType.REJECT,
          reason,
        }),
      );
      setReason('');
      onClose();
    },
  };

  const renderDocumentThumbnails = () => {
    const documents = [];

    if (isCompanyVerification && verification.cacDocUpload) {
      documents.push({ label: 'CAC Document', url: verification.cacDocUpload });
    }
    if (
      (isBusinessOwnerKycVerification || isCompanyDirectorVerification) &&
      verification?.idUpload
    ) {
      documents.push({ label: 'ID Document', url: verification.idUpload });
    }
    if (
      (isBusinessOwnerKycVerification || isCompanyDirectorVerification) &&
      verification.utilityBillUpload
    ) {
      documents.push({
        label: 'Utility Bill',
        url: verification.utilityBillUpload,
      });
    }

    return documents.map((doc, index) => (
      <div key={index}>
        <Typography variant="body1" fontWeight="bold">
          {doc.label}:
        </Typography>
        <AdvancedImage
          cldImg={cld
            .image(doc.url)
            .format('auto')
            .quality('auto')
            .resize(auto().gravity(autoGravity()).width(100).height(100))}
          style={{ cursor: 'pointer', maxHeight: '100px' }}
          onClick={() => openExpandedView(doc.url)}
        />
      </div>
    ));
  };

  const openExpandedView = (url: string) => {
    const link = cld
      .image(url)
      .format('auto')
      .quality('auto')
      .toURL() as string;

    if (link) window.open(link, '_blank');
  };

  const title = (() => {
    if ('supportEmail' in verification) return 'Company Verification';
    if ('fullName' in verification) return 'Company Director Verification';
    return 'Business Owner Verification';
  })();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="body1">
          {verification.companyName || (verification as any)?.name}
        </Typography>
        <b>{title}</b>
      </DialogTitle>

      <DialogContent dividers>
        {'supportEmail' in verification && (
          <CompanyVerificationComponent
            verification={verification as CompanyVerification}
            renderDocumentThumbnails={renderDocumentThumbnails}
          />
        )}
        {'fullName' in verification && (
          <CompanyDirectorVerificationComponent
            verification={verification as CompanyDirectorVerification}
            renderDocumentThumbnails={renderDocumentThumbnails}
          />
        )}
        {'firstName' in verification && (
          <BusinessOwnerKycVerificationComponent
            verification={verification as BusinessOwnerKycVerification}
            renderDocumentThumbnails={renderDocumentThumbnails}
          />
        )}
        <textarea
          placeholder={'Reason for Approval or Rejection'}
          value={reason}
          onChange={({ target: { value } }) => {
            setReason(value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsApproving(false);
            setOpenConfirmRequestVerification(true);
          }}
          color="error"
          variant="contained"
        >
          Reject
        </Button>
        <Button
          onClick={() => {
            setIsApproving(true);
            setOpenConfirmRequestVerification(true);
          }}
          color="primary"
          variant="contained"
        >
          Approve
        </Button>
      </DialogActions>

      <ConfirmationDialog {...confirmationDialogProps} />
    </Dialog>
  );
};

export default VerificationDialog;
