import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';

import Spinner from '@component/Spinner';
import { SpinnerVariant } from '@component/Spinner/Spinner';

const BasicDetails = ({
  energy,
  address,
  bandwidth,
  trxBalance,
  usdcBalance,
  usdtBalance,
  exchangeRate,
  isFetchingFeeWalletAddress,
}: {
  energy: number;
  address?: string;
  bandwidth: number;
  trxBalance: number;
  usdcBalance: number;
  usdtBalance: number;
  exchangeRate: number;
  isFetchingFeeWalletAddress: boolean;
}) => {
  const openRow = () => {
    window.open(`https://tronscan.org/#/address/${address}`, '_blank');
  };

  return (
    <Box py={2} position="relative">
      <Typography variant="h5" mb={2}>
        Wallet Details
      </Typography>

      <Button
        onClick={openRow}
        variant="contained"
        sx={{ position: 'absolute', top: 10, right: 10 }}
      >
        Blockchain View
      </Button>

      <Stack gap={1}>
        <Stack direction="row" alignItems="center">
          <Typography variant="body2">Address: {address}</Typography>
          {isFetchingFeeWalletAddress && (
            <Spinner variant={SpinnerVariant.ROTATE} size={10} />
          )}
        </Stack>

        <Typography variant="body2">
          TRX Balance: {trxBalance / 1_000_000} TRX ($
          {((trxBalance / 1_000_000) * exchangeRate).toFixed(2)})
        </Typography>

        <Typography variant="body2">
          USDT Balance: {usdtBalance} USDT
        </Typography>
        <Typography variant="body2">
          USDC Balance: {usdcBalance} USDC
        </Typography>
        <Typography variant="body2">Available Energy: {energy}</Typography>
        <Typography variant="body2">
          Available Bandwidth: {bandwidth}
        </Typography>
      </Stack>
    </Box>
  );
};

export default BasicDetails;
