import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL, SLICE_NAME } from '@variable';
import {
  VerificationRequest,
  VerificationRequests,
} from '@interface/verificationRequest.interface';
import VerificationDocType, {
  VerificationType,
} from '@enum/verificationDocType.enum';

const URL = `${API_URL}/admin`;

export const fetchRequests = createAsyncThunk(
  `${SLICE_NAME}/fetchRequests`,
  async (): Promise<VerificationRequests> => {
    const result = await axios(`${URL}/requests`);
    return result.data;
  },
);

export const approveOrRejectRequest = createAsyncThunk(
  `${SLICE_NAME}/approveOrRejectRequest`,
  async ({
    id,
    docType,
    action,
    reason,
  }: {
    id: string;
    reason: string;
    docType: VerificationDocType;
    action: VerificationType;
  }): Promise<VerificationRequest> => {
    const result = await axios.put(
      `${URL}/requests/${id}/${docType}/${action}`,
      { reason },
    );
    return result.data;
  },
);
