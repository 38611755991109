import React, { useEffect } from 'react';
import { Box, Divider, Stack } from '@mui/material';

import { useAppDispatch, useAppSelector } from '@hook/hooks.hook';
import {
  selectFeeWalletAddress,
  selectIsFetchingFeeWalletAddress,
} from '@selector/wallet.selector';
import { fetchFeeWalletAddress } from '@action/wallet.action';
import AppLoader from '@component/AppLoader';
import { selectUserRole } from '@selector/permission.selector';
import UserRole from '@enum/userRole.enum';
import Delegate from '@page/FeeWalletManagement/Delegate';
import UnStake from '@page/FeeWalletManagement/Unstake';

import BasicDetails from './BasicDetails';
import Stake from './Stake';

const FeeWalletManagement = () => {
  const dispatch = useAppDispatch();
  const {
    energy,
    address,
    bandwidth,
    trxBalance,
    usdcBalance,
    usdtBalance,
    exchangeRate,
    frozen,
  } = useAppSelector(selectFeeWalletAddress);
  const isFetchingFeeWalletAddress = useAppSelector(
    selectIsFetchingFeeWalletAddress,
  );
  const userRole = useAppSelector(selectUserRole);
  const isSuperAdmin = userRole === UserRole.SUPER_ADMIN;

  useEffect(() => {
    dispatch(fetchFeeWalletAddress());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showLoader = Boolean(isFetchingFeeWalletAddress && !address);

  return showLoader ? (
    <AppLoader />
  ) : (
    <Box>
      <BasicDetails
        energy={energy}
        address={address}
        bandwidth={bandwidth}
        trxBalance={trxBalance}
        usdcBalance={usdcBalance}
        usdtBalance={usdtBalance}
        exchangeRate={exchangeRate}
        isFetchingFeeWalletAddress={isFetchingFeeWalletAddress}
      />

      <Divider />

      <Stack>
        <Stake
          isSuperAdmin={isSuperAdmin}
          balance={trxBalance}
          frozen={frozen || []}
          exchangeRate={exchangeRate}
        />
        {isSuperAdmin && <UnStake isSuperAdmin={isSuperAdmin} />}
      </Stack>

      <Divider />

      {isSuperAdmin && (
        <Delegate isSuperAdmin={isSuperAdmin} balance={trxBalance} />
      )}
    </Box>
  );
};

export default FeeWalletManagement;
