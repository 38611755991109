import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';

import LoadingButton from '@component/LoadingButton';
import { useAppDispatch } from '@hook/hooks.hook';
import { delegateResources } from '@action/wallet.action';

const STAKE_REASON = ['BANDWIDTH', 'ENERGY'];

const Delegate = ({
  balance,
  isSuperAdmin,
}: {
  isSuperAdmin: boolean;
  balance: number;
}) => {
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState(1);
  const [resource, setResource] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'amount') setAmount(Number(e.target.value));
    if (e.target.name === 'recipientAddress')
      setRecipientAddress(e.target.value);
    else if (e.target.name === 'resource') setResource(e.target.value);
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    const result: any = await dispatch(
      delegateResources({ amount, resource, recipientAddress }),
    );
    console.log(result);

    setIsSubmitting(false);
  }, [amount, dispatch, resource, recipientAddress]);

  const disableBtn = !amount || amount < 1 || amount > balance || !resource;

  return (
    <Box py={2}>
      <Typography variant="h5" mb={2}>
        Delegating
      </Typography>

      {isSuperAdmin && (
        <Stack gap={1}>
          <Stack direction="row" gap={1}>
            <TextField
              name="amount"
              type="number"
              value={amount}
              onChange={handleChange}
              placeholder={`${balance} TRX`}
              inputProps={{ min: 1, max: balance }}
            />

            <FormControl sx={{ width: 196 }}>
              <InputLabel>Resource</InputLabel>
              <Select
                name="resource"
                value={resource}
                onChange={handleChange as any}
              >
                {STAKE_REASON.map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              name="recipientAddress"
              value={recipientAddress}
              onChange={handleChange}
              placeholder={`ADDRESS`}
            />

            <LoadingButton
              onClick={onSubmit}
              variant="contained"
              disabled={disableBtn}
              isLoading={isSubmitting}
            >
              Delegate
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default Delegate;
