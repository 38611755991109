import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';

import LoadingButton from '@component/LoadingButton';
import { unStakeTrx } from '@action/wallet.action';
import { useAppDispatch } from '@hook/hooks.hook';

const STAKE_REASON = ['BANDWIDTH', 'ENERGY'];

const UnStake = ({ isSuperAdmin }: { isSuperAdmin: boolean }) => {
  const dispatch = useAppDispatch();
  const [amount, setAmount] = useState(1);
  const [stakeReason, setStakeReason] = useState('');

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'amount') setAmount(Number(e.target.value));
    else if (e.target.name === 'stakeReason') setStakeReason(e.target.value);
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    await dispatch(unStakeTrx({ amount, stakeReason }));
    setIsSubmitting(false);
  }, [amount, dispatch, stakeReason]);

  const disableBtn = !amount || amount < 1 || !stakeReason;

  return (
    <Box py={2}>
      <Typography variant="h5" mb={2}>
        UnStaking
      </Typography>

      {isSuperAdmin && (
        <Stack gap={1}>
          <Stack direction="row" gap={1}>
            <TextField
              name="amount"
              type="number"
              value={amount}
              onChange={handleChange}
              inputProps={{ min: 1 }}
            />

            <FormControl sx={{ width: 196 }}>
              <InputLabel>Resource</InputLabel>
              <Select
                name="stakeReason"
                value={stakeReason}
                onChange={handleChange as any}
              >
                {STAKE_REASON.map((reason) => (
                  <MenuItem key={reason} value={reason}>
                    {reason}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <LoadingButton
              onClick={onSubmit}
              variant="contained"
              disabled={disableBtn}
              isLoading={isSubmitting}
            >
              UnStake
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default UnStake;
