import {
  ActionReducerMapBuilder,
  isAnyOf,
  isFulfilled,
  isPending,
  isRejected,
} from '@reduxjs/toolkit';

import InitialState from '@interface/initialState.interface';
import {
  authenticate,
  login,
  logout,
  refreshToken,
  updatePassword,
} from '@action/auth.action';
import { INITIAL_STATE, TOKEN_KEY } from '@variable';
import { fetchUserData, updateUser } from '@action/user.action';
import { approveOrRejectRequest, fetchRequests } from '@action/request.action';
import VerificationDocType from '@enum/verificationDocType.enum';
import {
  VerificationRequest,
  VerificationRequests,
} from '@interface/verificationRequest.interface';
import {
  activateCompany,
  fetchCompanies,
  fetchCompany,
  suspendCompany,
} from '@action/company.action';
import { activateApp, suspendApp } from '@action/app.action';
import {
  addAddress,
  fetchFeeWalletAddress,
  fetchWallets,
  hydrateWallet,
  lockAddress,
} from '@action/wallet.action';
import { fetchFeesAndTaxes } from '@action/taxFee.action';
import { enqueueWithdrawal, fetchWithdrawals } from '@action/withdrawal.action';
import WithdrawalStatus from '@enum/withdrawalStatus.enum';

export const extraReducers = (
  builder: ActionReducerMapBuilder<InitialState>,
) => {
  builder
    .addMatcher(
      isFulfilled(login, authenticate, refreshToken, fetchUserData, updateUser),
      (state: InitialState, { payload }) => {
        const { token, ...user } = payload || ({} as any);

        const newState = {
          ...state,
          isAuthenticating: false,
          isUpdatingUser: false,
          isAuthenticated: true,
        };

        if (token) window.sessionStorage.setItem(TOKEN_KEY, token);
        if (Object.values(user || {}).length)
          newState.user = { ...newState.user, ...user };

        return newState;
      },
    )
    .addMatcher(updatePassword.pending.match, (state: InitialState) => {
      return { ...state, isChangingPassword: true };
    })
    .addMatcher(
      isAnyOf(updatePassword.rejected.match, updatePassword.fulfilled.match),
      (state: InitialState) => {
        return { ...state, isChangingPassword: false };
      },
    )
    .addMatcher(
      isPending(login, authenticate, refreshToken, fetchUserData),
      (state: InitialState) => {
        return { ...state, isAuthenticating: true };
      },
    )
    .addMatcher(isPending(suspendApp, activateApp), (state: InitialState) => {
      return { ...state, isSuspendingApp: true };
    })
    .addMatcher(isRejected(suspendApp, activateApp), (state: InitialState) => {
      return { ...state, isSuspendingApp: false };
    })
    .addMatcher(
      isFulfilled(suspendApp, activateApp),
      (state: InitialState, { payload: updatedApp }) => {
        const { companyId } = updatedApp;

        return {
          ...state,
          isSuspendingApp: false,
          apps: {
            ...state.apps,
            [companyId]: state.apps?.[companyId]?.map((app) => {
              if (updatedApp.id === app.id) return { ...app, ...updatedApp };
              return app;
            }),
          },
        };
      },
    )
    .addMatcher(
      isPending(suspendCompany, activateCompany),
      (state: InitialState) => {
        return { ...state, isSuspendingCompany: true };
      },
    )
    .addMatcher(
      isRejected(suspendCompany, activateCompany),
      (state: InitialState) => {
        return { ...state, isSuspendingCompany: false };
      },
    )
    .addMatcher(
      isFulfilled(suspendCompany, activateCompany),
      (state: InitialState, { payload: updatedCompany }) => {
        return {
          ...state,
          isSuspendingCompany: false,
          companies: {
            ...state.companies,
            data: state.companies?.data.map((company) => {
              if (company.id === updatedCompany.id)
                return { ...company, ...updatedCompany };
              return company;
            }),
          },
        };
      },
    )
    .addMatcher(addAddress.pending.match, (state: InitialState) => {
      return { ...state, isFetchingWallets: true };
    })
    .addMatcher(addAddress.rejected.match, (state: InitialState) => {
      return { ...state, isFetchingWallets: false };
    })
    .addMatcher(
      addAddress.fulfilled.match,
      (state: InitialState, { payload: wallet }) => {
        return {
          ...state,
          isFetchingWallets: false,
          wallets: [...state.wallets].concat(wallet),
        };
      },
    )
    .addMatcher(fetchWallets.pending.match, (state: InitialState) => {
      return { ...state, isFetchingWallets: true };
    })
    .addMatcher(fetchWallets.rejected.match, (state: InitialState) => {
      return { ...state, isFetchingWallets: false };
    })
    .addMatcher(
      fetchWallets.fulfilled.match,
      (state: InitialState, { payload: wallets }) => {
        return { ...state, isFetchingWallets: false, wallets };
      },
    )
    .addMatcher(approveOrRejectRequest.pending.match, (state: InitialState) => {
      return { ...state, isVerifyingRequest: true };
    })
    .addMatcher(
      approveOrRejectRequest.rejected.match,
      (state: InitialState) => {
        return { ...state, isVerifyingRequest: false };
      },
    )
    .addMatcher(
      approveOrRejectRequest.fulfilled.match,
      (
        state: InitialState,
        {
          meta: {
            arg: { id, docType },
          },
        },
      ) => {
        const isCompany = docType === VerificationDocType.COMPANY;
        const isCompanyDirector =
          docType === VerificationDocType.COMPANY_DIRECTOR;
        let key = 'businessOwnerKycVerifications';

        if (isCompany) key = 'companyVerifications';
        if (isCompanyDirector) key = 'companyDirectorVerifications';

        return {
          ...state,
          isVerifyingRequest: false,
          verificationRequests: {
            ...((state.verificationRequests as VerificationRequests) || {}),
            [key]: (state.verificationRequests as any)?.[key as any].filter(
              (verificationRequest: VerificationRequest) =>
                verificationRequest.id !== id,
            ),
          },
        };
      },
    )
    .addMatcher(
      fetchCompany.pending.match,
      (state: InitialState, { meta: { arg: id } }) => {
        return {
          ...state,
          isFetchingCompany: {
            ...state.isFetchingCompany,
            [id]: true,
          },
        };
      },
    )
    .addMatcher(
      fetchCompany.rejected.match,
      (state: InitialState, { meta: { arg: id } }) => {
        return {
          ...state,
          isFetchingCompany: {
            ...state.isFetchingCompany,
            [id]: false,
          },
        };
      },
    )
    .addMatcher(
      fetchCompany.fulfilled.match,
      (state: InitialState, { payload: { apps, ...updatedCompany } }) => {
        const { data } = state.companies;
        const companies = { ...state.companies };

        if (!data?.length) {
          companies.data = [updatedCompany];
        } else {
          let hasBeenFound = false;
          companies.data = companies.data.map((company) => {
            if (company.id === updatedCompany.id) {
              hasBeenFound = true;
              return updatedCompany;
            }
            return company;
          });
          if (!hasBeenFound) companies.data.push(updatedCompany);
        }

        return {
          ...state,
          companies,
          apps: { ...state.apps, [updatedCompany.id]: apps },
          isFetchingCompany: {
            ...state.isFetchingCompany,
            [updatedCompany.id]: false,
          },
        };
      },
    )
    .addMatcher(fetchCompanies.pending.match, (state: InitialState) => {
      return { ...state, isFetchingCompanies: true };
    })
    .addMatcher(fetchCompanies.rejected.match, (state: InitialState) => {
      return { ...state, isFetchingCompanies: false };
    })
    .addMatcher(
      fetchCompanies.fulfilled.match,
      (state: InitialState, { payload: companies }) => {
        return {
          ...state,
          isFetchingCompanies: false,
          companies:
            companies.currentPage === 1
              ? companies
              : {
                  ...companies,
                  data: (state.companies?.data || []).concat(companies?.data),
                },
        };
      },
    )
    .addMatcher(fetchFeesAndTaxes.pending.match, (state: InitialState) => {
      return { ...state, isFetchingTaxesAndFees: true };
    })
    .addMatcher(fetchFeesAndTaxes.rejected.match, (state: InitialState) => {
      return { ...state, isFetchingTaxesAndFees: false };
    })
    .addMatcher(
      fetchFeesAndTaxes.fulfilled.match,
      (state: InitialState, { payload: taxAndFees }) => {
        return {
          ...state,
          isFetchingTaxesAndFees: false,
          taxAndFees:
            taxAndFees.currentPage === 1
              ? taxAndFees
              : {
                  ...taxAndFees,
                  data: (state.taxAndFees?.data || []).concat(taxAndFees?.data),
                },
        };
      },
    )
    .addMatcher(fetchWithdrawals.pending.match, (state: InitialState) => {
      return { ...state, isFetchingTaxesAndFees: true };
    })
    .addMatcher(fetchWithdrawals.rejected.match, (state: InitialState) => {
      return { ...state, isFetchingTaxesAndFees: false };
    })
    .addMatcher(
      fetchWithdrawals.fulfilled.match,
      (state: InitialState, { payload: withdrawals }) => {
        return {
          ...state,
          isFetchingTaxesAndFees: false,
          withdrawals:
            withdrawals.currentPage === 1
              ? withdrawals
              : {
                  ...withdrawals,
                  data: (state.withdrawals?.data || []).concat(
                    withdrawals?.data,
                  ),
                },
        };
      },
    )
    .addMatcher(
      enqueueWithdrawal.fulfilled.match,
      (state: InitialState, { payload: id }) => {
        return {
          ...state,
          withdrawals: {
            ...state.withdrawals,
            data: state.withdrawals.data.map((withdrawal) => {
              if (withdrawal.id === id)
                return { ...withdrawal, status: WithdrawalStatus.PROCESSING };

              return withdrawal;
            }),
          },
        };
      },
    )
    .addMatcher(fetchRequests.pending.match, (state: InitialState) => {
      return { ...state, isFetchingVerificationRequests: true };
    })
    .addMatcher(fetchRequests.rejected.match, (state: InitialState) => {
      return { ...state, isFetchingVerificationRequests: false };
    })
    .addMatcher(
      fetchRequests.fulfilled.match,
      (state: InitialState, { payload: verificationRequests }) => {
        return {
          ...state,
          verificationRequests,
          isFetchingVerificationRequests: false,
        };
      },
    )
    .addMatcher(
      isAnyOf(
        logout.match,
        login.rejected.match,
        authenticate.rejected.match,
        refreshToken.rejected.match,
        fetchUserData.rejected.match,
      ),
      () => {
        window.sessionStorage.removeItem(TOKEN_KEY);
        return INITIAL_STATE;
      },
    )
    .addMatcher(fetchFeeWalletAddress.pending.match, (state: InitialState) => ({
      ...state,
      isFetchingFeeWalletAddress: true,
    }))
    .addMatcher(
      fetchFeeWalletAddress.rejected.match,
      (state: InitialState) => ({
        ...state,
        isFetchingFeeWalletAddress: false,
      }),
    )
    .addMatcher(
      fetchFeeWalletAddress.fulfilled.match,
      (state: InitialState, { payload: feeWalletAddress }) => ({
        ...state,
        feeWalletAddress,
        isFetchingFeeWalletAddress: false,
      }),
    )
    .addMatcher(
      hydrateWallet.fulfilled.match,
      (state: InitialState, { payload: updatedWallet }) => ({
        ...state,
        wallets: state.wallets.map((wallet) => {
          if (wallet.id === updatedWallet.id) return updatedWallet;
          return wallet;
        }),
      }),
    )
    .addMatcher(
      lockAddress.fulfilled.match,
      (
        state: InitialState,
        {
          meta: {
            arg: { addressId, isLocked },
          },
        },
      ) => ({
        ...state,
        wallets: state.wallets.map((wallet) => {
          if (wallet.id === addressId) return { ...wallet, isLocked };
          return wallet;
        }),
      }),
    );
};

export const reducers = {};
